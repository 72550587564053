<template>
    <div>
        <nav class="nav_side col-lg-2 col-md-3 col-sm-3">

            <ul class="nav nav-pills nav-stacked affix">
                <li>
                    <div class="form-group">
                        <label>CDI Location</label>
                        <select class='form-control' v-model='cdiLocation'>
                            <option v-for="[id, cdiLocation] in cache.cdiLocations.CDI_LOCATIONS" :key="id" :value="id">{{ cdiLocation }}</option>
                        </select>
                    </div>
                </li>
                <li class="cdi_tab">
                    <a class='pointer' @click='selectAll'>Check All</a>
                </li>
                <li id="send_invoices" class="cdi_tab" style="width: 158px;">
                    <a class='pointer' @click='getPdfs'>Invoice</a>
                </li>
            </ul>
        </nav>
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter fill">
            <h1>Invoicing</h1>
            <ul class="list-unstyled">
                <li v-for="file in invoiceFiles" :key="file" >
                    <ProtectedLink :link="file" :linkType="'link'" :text="file" :type="'trackerInvoice'" />

                </li>
            </ul>
            <div class="table-responsive">
                <table class="table">
                    <tbody>
                    <tr>
                        <th>Invoice</th>
                        <th>Order</th>
                        <th>Ship Date</th>
                        <th>Company</th>
                        <th>PO</th>
                        <th>Amount</th>
                        <th>Bill To</th>
                    </tr>
                    <tr v-for="invoice in invoices" :key="invoice.tracker_id">
                        <td>
                            <input type="checkbox" v-model="selectedInvoices" :value="invoice.tracker_id" />
                        </td>
                        <td>
                            <router-link :to="`/tracker/${invoice.tracker_id}`">
                                {{ invoice.tracker_id }}
                            </router-link>
                            <sup v-show="invoice.check_invoice">
                                NEEDS CHECKING
                            </sup>
                        </td>
                        <td>{{ invoice.actual_ship }}</td>
                        <td><router-link :to="`/company/${invoice.company_id}`">{{ invoice.company_name }}</router-link></td>
                        <td>{{ invoice.distributor_po }}</td>
                        <td>{{ invoice.total_amount }}</td>
                        <td>
                            <select class="form-control" @change='selectBillTo(invoice.company_bill_to_id, invoice.tracker_id)' v-model="invoice.company_bill_to_id" >
                                <option value="0">Not Set</option>
                                <option v-for="(billTo, id) in invoice.bill_to_options" :key="id" :value="billTo.company_bill_to_id">{{ billTo.cdi_address_label }}</option>
                            </select>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </main>
    </div>
</template>

<script>

import { store } from '@/store/Tool.store';
import cdiLocationCache from '@/cache/cdiLocation.cache';
import ProtectedLink from "@/components/utils/ProtectedLink";

export default {
    name: 'Invoicing',
    data() {
        return {
            cdiLocation: 1,
            invoices: [],
            selectedInvoices: [],
            cache: {
                cdiLocations: cdiLocationCache
            },
            invoiceFiles: [],
        }
    },
    components: {ProtectedLink},
    methods: {
        getInvoices: function() {
            let params = {'params[numbers][cdi_location_id]' : this.cdiLocation};
            store.reportsApi('/trackers/unsent_invoices', params).then( (res) => {
                for (let i = 0; i < res.data.length; i++) {
                    res.data[i].selected = false;
                }
                this.invoices = res.data;
            })
            this.selectedInvoices = [];
        },
        selectAll: function() {
            for (let i = 0; i < this.invoices.length; i++) {
                if (this.selectedInvoices.includes(this.invoices[i].tracker_id)) {
                    continue;
                }
                this.selectedInvoices.push(this.invoices[i].tracker_id);
            }
        },
        getPdfs: function() {
            if (!this.selectedInvoices.length) {
                return;
            }
            store.api(`/tracker_invoice_pdf/deliver_invoices`, {'params': this.selectedInvoices }).then( (res) => {
                if (res.opt === 'get') {
                    this.invoiceFiles.push(res.filename);
                }
                this.getInvoices();
            })
        },
        selectBillTo: function(billToId, trackerId) {
            store.api(`/tracker/save_details/${ trackerId }`, {'params[company_bill_to_id]' : billToId});
        }
    },
    watch: {
        cdiLocation: {
            immediate: true,
            handler: function() {
                this.getInvoices();
            }
        }
    }

}

</script>

<style scoped>

</style>